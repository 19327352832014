<template>
  <v-dialog :value="addNewDialogAddress" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Agregar dirección</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <AddressFields
            :address1="addAddressInput.address"
            :address1Fn="editAddAddressInput"
            :address2="addAddressInput.address2"
            :address2Fn="editAddAddress2Input"
            :province="addAddressInput.province"
            :provinceFn="editAddProvinceInput"
            :country="addAddressInput.country"
            :countryFn="editAddCountryInput"
            :zip="addAddressInput.zip"
            :zipFn="editAddZipInput"
            :city="addAddressInput.city"
            :cityFn="editAddCityInput"
            :dark="false"
          />
        </v-container>
        <small>*Recuerda que esta direccion se utilzara en paqueteria</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue en-1" text @click="closeAddNewAddressDialog">
          Close
        </v-btn>
        <v-btn color="blue en-1" text @click="addAddress"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddressFields from "./addressInputs.vue";
export default {
  components: {
    AddressFields,
  },
  computed: {
    ...mapState(["addNewDialogAddress", "addAddressInput"]),
  },
  methods: {
    ...mapMutations([
      "closeAddNewAddressDialog",
      "editAddAddressInput",
      "editAddAddress2Input",
      "editAddProvinceInput",
      "editAddCountryInput",
      "editAddZipInput",
      "editAddCityInput",
    ]),
    ...mapActions(["addAddress"]),
  },
};
</script>

<style>
</style>