<template>
  <div id="app">
    <div id="header">
      <div id="icono">
        <v-img
          src="@/assets/Logo-Radio-Trunk_blanco.png"
          width="300px"
        ></v-img>
      </div>
      <div id="menu">
        <v-menu bottom min-width="300px" rounded offset-y >
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="primary" size="60">
                <span class="white--text headline">{{
                  name.substr(0,1)
                }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="primary">
                  <span class="white--text headline">{{
                    name.substr(0,1)
                  }}</span>
                </v-avatar>
                <h3>{{ name }}</h3>
                <p class="caption mt-1">
                  {{ email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="LogOut">
                  Cerrar Sesion
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div id="container">
      <v-container class="mb-2 mt-5">
        <v-row style="margin: 0px">
          <v-col>
            <h1 id="titulo">Tienda en linea</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-stepper vertical :value="e1">
              
              <v-stepper-step
                @click="changeStep(1)"
                :complete="e1 > 1"
                :step="1"
              >
                Seleccionar productos
              </v-stepper-step>
              <AddProducts />
              <v-stepper-step
                @click="changeStep(2)"
                :complete="e1 > 2"
                :step="2"
              >
                Agregar grupos
              </v-stepper-step>
              <AddGroups />
              <v-stepper-step
                @click="
                  changeStep(3);
                  methodX();
                "
                :complete="e1 > 3"
                :step="3"
              >
                Nombres de las cuentas
              </v-stepper-step>
              <AddAccounts ref="x" />
              <v-stepper-step
                @click="changeStep(4)"
                :complete="e1 > 4"
                :step="4"
              >
                Direccion de envio
              </v-stepper-step>
              <AddressToSend/>
              <v-stepper-step
                @click="
                  changeStep(5);
                  methodY();
                "
                :complete="e1 > 5"
                :step="5"
              >
                Pago
              </v-stepper-step>
              <SummaryAndPayment ref="y" />
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>
      <AddAddress/>
      <EditAddress/>
      <CounterCart/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddGroups from "../components/addGroups.vue";
import AddProducts from "../components/addProducts.vue";
import AddAccounts from "../components/addAccounts.vue";
import AddAddress from "../components/addAddress.vue"
import EditAddress from "../components/editAddress.vue"
import CounterCart from "../components/counterCartRadio.vue"
//import TypeOfLicence from "../components/addLicenceType.vue";
import SummaryAndPayment from "../components/summaryAndPayment.vue";
import AddressToSend from '../components/addressToSend.vue'
export default {
  name: "Home",
  computed: {
    ...mapState(["e1"]),
    ...mapState('LogIn',['name','email'])
  },
  components: {
    AddGroups,
    AddProducts,
    AddAccounts,
    //TypeOfLicence,
    SummaryAndPayment,
    CounterCart,
    AddressToSend,
    AddAddress,
    EditAddress
  },
  methods: {
    ...mapMutations(["changeStep"]),
    ...mapActions('LogIn',['LogOut']),
    methodX() {
      this.$refs.x.updateComp();
    },
    methodY() {
      this.$refs.y.updateComp();
    }
  },
  mounted(){
    this.$store.dispatch('LogIn/getUserInfo',null,{root:true})
    this.$store.commit('LogIn/updateEmail',localStorage.email,{root:true})
    this.$store.commit('LogIn/updateName',localStorage.name,{root:true})
    this.$store.dispatch('getCheckoutID')
  }
};
</script>

<style >
#app,
#color {
  height: 100%;
  width: 100%;
  background: rgb(0, 93, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 93, 255, 1) 0%,
    rgba(21, 40, 71, 1) 100%
  );
}
#container {
  background: white;
  width: 90%;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
}
#header {
  width: 60%;
  margin: 0px auto;
  height: 50px;
  margin-top: 10px;
  padding-bottom: 10px;
}
#icono{
  width: 50%;
  height: 100%;
  float: left;
}
#menu{
  width: 20%;
  float: right;
  padding-top: 5px;
}
#titulo {
  text-align: center;
  background: #152847;
  border-radius: 20px 20px;
  color: white;
}
</style>

<!--382907-->
