import { render, staticRenderFns } from "./addGroups.vue?vue&type=template&id=12be69d1&"
import script from "./addGroups.vue?vue&type=script&lang=js&"
export * from "./addGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VAlert,VAvatar,VBtn,VCard,VCol,VContainer,VDivider,VIcon,VListItem,VListItemAvatar,VRow,VStepperContent,VTextField,VVirtualScroll})
