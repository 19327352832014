<template>
  <v-stepper-content step="3">
    <v-alert dense type="info">
      Los nombres de las cuentas es la manera como identificaras los radios
      dentro de los grupos
    </v-alert>
    <v-alert type="warning" dense>
      Resguarde sus contraseñas ya que le serviran para usar las cuentas de sus
      radios
    </v-alert>
    <v-container>
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          align="center"
          justify="center"
          v-for="(product, indexProducts) in Products"
          :key="indexProducts"
        >
          <h1 v-if="checkProduct(product.variants)">{{ product.title }}</h1>
          <v-container>
            <v-row
              v-for="(variant, indexVariants) in product.variants"
              :key="indexVariants"
            >
              <v-col v-if="variant.ToBuy.count > 0">
                <h1 v-if="variant.text != 'Default Title'">
                  {{ variant.text }}
                </h1>
                <div
                  v-for="(radio, indexRadio) in variant.ToBuy.accounts"
                  :key="indexRadio"
                  id="fields"
                >
                  <v-text-field
                    clearable
                    outlined
                    rounded
                    maxlength="15"
                    hint="Ejemplo: Guardia1, Personal4, etc."
                    :value="radio.name"
                    dense
                    :rules="[rules.onlyLettersAndNumbers,rules.maxLengthAccount]"
                    append-outer-icon="mdi-cog"
                    @input="
                      changeAccountName({
                        value: $event,
                        indexP: indexProducts,
                        indexV: indexVariants,
                        indexR: indexRadio,
                      })
                    "
                    @click:append-outer="
                      openDialogConfiguration({
                        indexP: indexProducts,
                        indexV: indexVariants,
                        indexR: indexRadio,
                      })
                    "
                    :label="'Nombre del radio #' + (indexRadio + 1)"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"> mdi-cog </v-icon>
                        </template>
                        <span>Configuracion del radio</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <Password
                    :label="'Contraseña del radio #' + (indexRadio + 1)"
                    :value="radio.password"
                    :Fn="changeAccountPassword"
                    :valuesPass="{
                      indexP: indexProducts,
                      indexV: indexVariants,
                      indexR: indexRadio,
                    }"
                    :randomPass="true"
                    :generateRandomPass="generateRandomPass"
                    :randomValues="{
                      indexP: indexProducts,
                      indexV: indexVariants,
                      indexR: indexRadio,
                    }"
                    :rules="[rules.onlyLettersAndNumbers,rules.maxLengthAccount]"
                    :dense="true"
                    :dark="false"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>

          <ConfigurationWindow ref="y" />
        </v-col>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>
import ConfigurationWindow from "./configurationWindow.vue";
import Password from "./passwordField.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      rules: {
        onlyLettersAndNumbers: value => {
          var alphaExp = /^[a-zA-Z0-9]+$/;
          return alphaExp.test(value) || "Solo se admiten números y letras";
        },
        maxLengthAccount: value => value.length <= 15 || "Max 15 characters",
      },
    };
  },
  components: {
    ConfigurationWindow,
    Password,
  },
  computed: {
    ...mapState(["Products"]),
  },
  methods: {
    checkProduct(value) {
      let el = value.reduce((arr, el) => {
        if (el.ToBuy.count > 0) {
          arr = arr + el.ToBuy.count;
        }
        if (arr > 0) {
          return true;
        } else {
          return false;
        }
      }, 0);
      if (el > 0) {
        return true;
      } else {
        return false;
      }
    },
    updateComp() {
      this.$forceUpdate();
    },
    ...mapMutations([
      "changeAccountName",
      "changeAccountPassword",
      "generateRandomPass",
    ]),
    ...mapActions(["openDialogConfiguration"]),
  },
};
</script>

<style>
@media (min-width: 778px) {
  #fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>