var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" Los nombres de las cuentas es la manera como identificaras los radios dentro de los grupos ")]),_c('v-alert',{attrs:{"type":"warning","dense":""}},[_vm._v(" Resguarde sus contraseñas ya que le serviran para usar las cuentas de sus radios ")]),_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},_vm._l((_vm.Products),function(product,indexProducts){return _c('v-col',{key:indexProducts,attrs:{"cols":"12","align":"center","justify":"center"}},[(_vm.checkProduct(product.variants))?_c('h1',[_vm._v(_vm._s(product.title))]):_vm._e(),_c('v-container',_vm._l((product.variants),function(variant,indexVariants){return _c('v-row',{key:indexVariants},[(variant.ToBuy.count > 0)?_c('v-col',[(variant.text != 'Default Title')?_c('h1',[_vm._v(" "+_vm._s(variant.text)+" ")]):_vm._e(),_vm._l((variant.ToBuy.accounts),function(radio,indexRadio){return _c('div',{key:indexRadio,attrs:{"id":"fields"}},[_c('v-text-field',{attrs:{"clearable":"","outlined":"","rounded":"","maxlength":"15","hint":"Ejemplo: Guardia1, Personal4, etc.","value":radio.name,"dense":"","rules":[_vm.rules.onlyLettersAndNumbers,_vm.rules.maxLengthAccount],"append-outer-icon":"mdi-cog","label":'Nombre del radio #' + (indexRadio + 1)},on:{"input":function($event){return _vm.changeAccountName({
                      value: $event,
                      indexP: indexProducts,
                      indexV: indexVariants,
                      indexR: indexRadio,
                    })},"click:append-outer":function($event){return _vm.openDialogConfiguration({
                      indexP: indexProducts,
                      indexV: indexVariants,
                      indexR: indexRadio,
                    })}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-cog ")])]}}],null,true)},[_c('span',[_vm._v("Configuracion del radio")])])]},proxy:true}],null,true)}),_c('Password',{attrs:{"label":'Contraseña del radio #' + (indexRadio + 1),"value":radio.password,"Fn":_vm.changeAccountPassword,"valuesPass":{
                    indexP: indexProducts,
                    indexV: indexVariants,
                    indexR: indexRadio,
                  },"randomPass":true,"generateRandomPass":_vm.generateRandomPass,"randomValues":{
                    indexP: indexProducts,
                    indexV: indexVariants,
                    indexR: indexRadio,
                  },"rules":[_vm.rules.onlyLettersAndNumbers,_vm.rules.maxLengthAccount],"dense":true,"dark":false}})],1)})],2):_vm._e()],1)}),1),_c('ConfigurationWindow',{ref:"y",refInFor:true})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }