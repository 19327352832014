<template>
  <div id="counter-box">
    <p id="counter">{{sumDevices}}</p>
    <v-icon id="icono"> mdi-cart </v-icon>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    
    computed:{
        ...mapState(['sumDevices']),
    }
};
</script>

<style>
#counter-box {
  background-color: #007bff;
  position: fixed;
  top: 80px;
  right: 10px;
  border-radius: 20px;
  align-items: center;
  width: 50px;
}
#icono{
    margin: 0px auto;
    color: white;
    padding: 25px;
    padding-top: 0;
    
}
#counter{
    background: white;
    text-align: center;
    border-radius: 20px 20px 0 0;
}
</style>