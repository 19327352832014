<template>
  <v-dialog :value="editAddressDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Editar direccion</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <AddressFields
            :address1="editAddressInput.address"
            :address1Fn="updateAddressInputToEdit"
            :address2="editAddressInput.address2"
            :address2Fn="updateAddress2InputToEdit"
            :province="editAddressInput.province"
            :provinceFn="updateProvinceInputToEdit"
            :country="editAddressInput.country"
            :countryFn="updateCountryInputToEdit"
            :zip="editAddressInput.zip"
            :zipFn="updateZipInputToEdit"
            :city="editAddressInput.city"
            :cityFn="updateCityInputToEdit"
          />
        </v-container>
        <small>*Recuerda que esta direccion se utilzara en paqueteria</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeEditAddressDialog">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="editAddress"> 
          Save 
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddressFields from "./addressInputs.vue";
export default {
  components: {
    AddressFields,
  },
  computed: {
    ...mapState(["editAddressDialog", "editAddressInput"]),
  },
  methods: {
    ...mapMutations([
      "closeEditAddressDialog",
      "changeEditAddressInput",
      "updateAddressInputToEdit",
      "updateAddress2InputToEdit",
      "updateProvinceInputToEdit",
      "updateCountryInputToEdit",
      "updateZipInputToEdit",
      "updateCityInputToEdit"

    ]),
    ...mapActions(["editAddress"]),
  },
};
</script>

<style>
</style>