<template>
  <v-stepper-content step="2">
    <v-alert
      dense
      type="info"
    >
      Los grupos son canales en donde tus radios se podran comunicar entre ellos
    </v-alert>
    <v-card class="mb-5" color="#005dff" style="border-radius: 25px">
      <h2 style="margin-left: 30px; color: white">Grupos</h2>
      <p class="white--text" style="text-align:center">
        Escribe el nombre del grupo y despues dale click al boton de '+' o
        presiona enter para agregarlo
      </p>
      <v-text-field
        :value="inputGroup"
        style="border-radius: 25px"
        class="mx-5 my-3"
        outlined
        label="Nombre del Grupo"
        dark
        hint="Ejemplo: Oficina,Recepcion"
        append-outer-icon="mdi-plus"
        maxlength="30"
        @keyup.enter="setNewGroup"
        @click:append-outer="setNewGroup"
        @input="updateGroup($event)"
      ></v-text-field>
    </v-card>
    <v-virtual-scroll
      :items="Groups"
      height="130"
      item-height="60"
      class="mb-8"
    >
      <template v-slot:default="{ item, index }">
        <v-list-item :key="item" style="background: #fff">
          <v-container>
            <v-row>
              <v-col cols="9">
                <v-list-item-avatar>
                  <v-avatar color="primary" size="30" class="white--text">
                    {{ index + 1 }}
                  </v-avatar>
                </v-list-item-avatar>
                <strong> {{ item }}</strong>
              </v-col>
              <v-col cols="1">
                <v-btn
                  small
                  color="red"
                  icon
                  class="mt-3"
                  @click="removeGroup(index)"
                  ><v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1">
                <v-btn
                  small
                  color="primary"
                  icon
                  class="mt-3"
                  @click="editGroup({ index: index, value: item })"
                  ><v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item>

        <v-divider></v-divider>
      </template>
    </v-virtual-scroll>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState(["Groups", "inputGroup"]),
  },
  methods: {
    ...mapActions(["setNewGroup", "editGroup"]),
    ...mapMutations(["updateGroup", "removeGroup"]),
  },
};
</script>

<style>
</style>