<template>
  <v-card class="mx-auto my-12" >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-carousel>
      <v-carousel-item
        v-for="(item, i) in object.images"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-img
          contain
          height="500"
          :src="item.node.originalSrc"
          :href="item.node.originalSrc"
          target="_blank"
        >
        </v-img>
      </v-carousel-item>
    </v-carousel>

    <v-card-title style="background: #152847;font-size:17px" class="white--text">{{
      object.title
    }}</v-card-title>
    <v-select
      prefix="Licence Type:"
      class="mx-2"
      :items="object.variants"
      @change="selectVariant({ value: $event, index: index })"
      :value="object.chooseLicenceType"
    >
    </v-select>

    <v-card-text>
      <div class="my-4 text-subtitle-1 green--text">
        $
        {{
          object.variants.find((element) => {
            return element.value.id == object.chooseLicenceType.id;
          }).price
        }}
      </div>
      <div class="my-4 text-subtitle-1 blue--text">
        Disponibles •
        {{
          object.variants.find((element) => {
            return element.value.id == object.chooseLicenceType.id;
          }).quantity
        }}
        pzas
      </div>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-text-field
        suffix="pzas"
        @input="
          Fn({
            value: $event,
            licence: object.chooseLicenceType,
            model: object.title,
            index: index,
          });
          checkValue($event);
        "
        :value="object.variants[object.chooseLicenceType.index].ToBuy.count"
        class="mx-10"
        label="Cantidad"
        type="number"
        min="0" pattern="^[0-9]+"
        outlined
        dense
      ></v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      value: 0,
    };
  },
  props: {
    object: Object,
    Fn: Function,
    index: Number,
  },
  methods: {
    checkValue(e) {
      this.value = Math.abs(e);
    },
    ...mapMutations(["selectVariant"]),
  },
};
</script>

<style>
</style>