<template>
  <v-dialog
    :value="dialogConfiguration"
    persistent
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Configuracion de cuenta</span>
      </v-card-title>
      <v-card-text>
        <v-autocomplete multiple :items="privilegeList" small-chips @change="changeConfiguration($event)" :value="Products[index1].variants[index2].ToBuy.accounts[index3].conf"></v-autocomplete>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="closeDialogConfiguration">
          Cerrar
        </v-btn>
        
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState([
      "privilegeList",
      "dialogConfiguration",
      "index1",
      "index2",
      "index3",
      "Products",
    ]),

  },

  methods: {
    ...mapMutations(["closeDialogConfiguration","changeConfiguration"]),
  },
};
</script>

<style>
</style>