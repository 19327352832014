<template>
  <v-stepper-content step="4">
    <v-radio-group @change="setAddressToSend($event)" :value="AddressToSend">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            xl="4"
            v-for="(address, index) in ListOfAddress"
            :key="index"
            align="center"
            justify="center"
          >
            <v-card class="mx-auto" hover @click="setAddressToSend(address)">
              <v-card-title class="primary--text ml-5">
                <v-radio :value="address"> </v-radio>
                <v-btn text @click="openEditAddressDialog({address:address,index:index})"><v-icon color="success">mdi-pencil</v-icon></v-btn>
                <v-btn text @click="deleteAddress(index)"><v-icon color="red">mdi-delete</v-icon></v-btn>
              </v-card-title>
              <p class="px-2">{{ address.address + " " + address.address2+" "+ address.city + " " + address.province + " " + address.country+" "+address.zip}}</p>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" xl="4">
            <v-card class="mx-auto" hover @click="openAddNewAddressDialog">
              <v-card-title class="primary--text ml-5" >
                <v-icon color="primary">mdi-plus</v-icon> Agregar otra direccion
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-radio-group>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState(["ListOfAddress", "AddressToSend"]),
  },
  methods: {
    ...mapActions(["getAddress"]),
    ...mapMutations(['setAddressToSend','openAddNewAddressDialog','openEditAddressDialog']),
    ...mapActions(['deleteAddress'])
  },
  created() {
    this.getAddress();
  },
};
</script>

<style>
</style>