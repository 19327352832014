<template>
  <v-stepper-content step="1">
    <v-alert
      dense
      type="info"
    >
      Seleccione la cantidad de radios que requiera dependiendo del modelo y el periodo de servicio que necesite. 
    </v-alert>
    <v-container>
      <v-row align="center"
          justify="center">
        <v-col
          cols="12"
          sm="12"
          md="6"
          xl="4"
          align="center"
          justify="center"
          v-for="(x,index) in Products"
          :key="index"
          
        >
          <ProductCard
            :object="x"
            :index="index"
            :Fn="updateCheckout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductCard from "./productCard.vue";
export default {
  components: {
    ProductCard,
  },
  computed: {
    ...mapState(["Products"]),
  },
  methods:{
    ...mapActions(['getProducts','updateCheckout'])
  },
  created(){
    this.getProducts()
  }
};
</script>

<style>
</style>