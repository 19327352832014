<template>
  <v-stepper-content step="5">
    <!--<v-container>
      <v-row>
        <v-col cols="12" sm="12" md="6" xl="6">
          <v-card class="mx-auto">
            <v-card-title>Detalles de la compra</v-card-title>
            <v-expansion-panels flat>
            <v-expansion-panel v-for="(item, i) in Amounts" :key="i">
              <v-expansion-panel-header
                >Modelo {{ item.model }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item v-for="(account, index) in item.accounts" :key="index">
                    {{account}}
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <p class="text--black"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-title>Resumen de pago</v-card-title>
          <p class="pl-10 text--black"></p>
          <p class="pl-14 text--black">Radios: $ {{formatPrice(CalcRadiosTotal())}} MXN.</p>
          <p class="pl-14 text--black">Licencias: $ {{formatPrice(CalcLicenceTotal())}} MXN.</p>
          <p class="pl-14 text--black pb-10 font-weight-bold">Total: $ {{formatPrice(CalcTotal())}} MXN.</p>
          <StripeCard :tokenCreated="tokenCreated"/>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6" xl="6">
          <v-card class="mx-auto">
          </v-card>
        </v-col>
      </v-row>
    </v-container>-->
  <v-btn color="primary" rounded @click="goToPay()">Proceder a pagar</v-btn>
  </v-stepper-content>
</template>

<script>
import { mapActions } from 'vuex';

export default {

  computed:{
    
  },
  methods:{
    ...mapActions(['goToPay']),
    tokenCreated(token){
        console.log(token)
        let value = { 
          token: token.id, 
          amount: this.CalcTotal(), 
          description: 'Radiotrunk ecommerce platform'
        }
        this.$store.dispatch('paymmentMiddleware',value)
    },
    CalcTotal(){
      return this.CalcRadiosTotal() + this.CalcLicenceTotal()
    },
    GetProductPrice(model){
      let product = this.Products.find(product => product.model === model)
      return product.base_price
    },
    CalcRadiosTotal(){
      if(this.Amounts){
        let subtotal = 0;
        this.Amounts.forEach(element => {
          subtotal = subtotal + (this.GetProductPrice(element.model) * parseInt(element.amount))
        });
        return subtotal
      }
    },
    GetLicencePrice(months){
      let licence = this.typeOfLicence.find(licence => licence.value === months)
      return licence.price
    },
    CalcLicenceTotal(){
      if(this.Amounts){
        let subtotal = 0;
        this.Amounts.forEach(element => {
          subtotal = subtotal + (this.GetLicencePrice(this.LicenceType) * parseInt(element.amount))
        })
        return subtotal
      }
    },
    updateComp() {
      this.$forceUpdate();
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
};
</script>

<style>
</style>